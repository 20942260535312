import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import useOnce from "../../../core/utils/hooks/useOnce";
import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import Loader from "../../../molecules/loader/Loader";
import PollingLoader from "../../../organisms/polling-loader/PollingLoader";
import {
  useResultUuid,
  useSetResultState,
} from "../../../organisms/result/use-cases/result-use-cases";
import { PageId } from "../../../settings/pages";
import { Events } from "../../../utils/analytics";
import { getCurrentURLSearchParams } from "../../../utils/browser-features";
import useTrackPageViewed from "../../../utils/hooks/useTrackPageViewed";
import * as styles from "../../Binding.module.scss";

const Page = () => {
  const resultUuid = useResultUuid();
  const setResultState = useSetResultState();
  const { t } = useTranslation();
  const urlSearchParams = getCurrentURLSearchParams();
  const originalQueryParams = urlSearchParams?.toString() || "";
  const nextUrl = `/results/${resultUuid ?? ""}/welcome/?${originalQueryParams}`;

  useTrackPageViewed(Events.BINDING_VIEWED_BROWSER);

  const handleBinding = useOnce(() => {
    setResultState({
      status: "binding",
    });
  });

  return (
    <PollingLoader
      nextUrl={nextUrl}
      statusesCallbacks={{
        requested: ({ waitAndRetry }) => waitAndRetry(),
        binding: ({ waitAndRetry }) => {
          handleBinding();
          waitAndRetry();
        },
        completed: ({ stopPolling }) => stopPolling(),
      }}
    >
      <Loader title={t("binding_page.title")} />
    </PollingLoader>
  );
};

export default withFunnelWrapper(Page, styles.binding, PageId.binding, false);

export const query = graphql`
  query BindingRequestQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
